import { useQuery } from '@tanstack/react-query';
import {
	Button,
	Card,
	Form,
	Input,
	InputNumber,
	Popconfirm,
	message,
} from 'antd';
import { WOOD_CODE } from '../../../../server/src/wood-service/code';
import { isNil } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useGlobalManagementStore } from '../apartment/myInfo/useGlobalManagement';
import { honoClient } from './honoClient';
import { roundFix2 } from './roundFix2';

export default function TransactionEdit() {
	const [form] = Form.useForm();
	const [searchParams, setSearchParams] = useSearchParams();
	const { globalManagement, setGlobalManagement } = useGlobalManagementStore();
	const uuid = searchParams.get('uuid');
	const [rows, setRows] = useState<Key[]>([]);
	const navigate = useNavigate();
	const detailQuery = useQuery({
		queryKey: ['transactionDetail', uuid],
		queryFn: async () => {
			const res = await honoClient.transactionDetail.$post({
				json: { uuid: uuid ?? '' },
			});

			const queryRes = await res.json();

			return queryRes;
		},
		enabled: !isNil(uuid),
		refetchOnWindowFocus: false,
	});

	useEffect(() => {
		if (detailQuery.isSuccess) {
			// 如果没有编辑过 要把数据结构转一下
			const nCommodity = detailQuery?.data?.stockRows?.map((a) => {
				a.unitPrice = Number.parseFloat(a.unitPrice);

				return a;
			});

			const initialValue = {
				uuid: uuid,
				...detailQuery.data,
				commodity: detailQuery?.data?.commodity
					? detailQuery?.data?.commodity
					: nCommodity,
			};
			console.log('initialValue', initialValue);

			form.setFieldsValue(initialValue);
		}
	}, [detailQuery.isSuccess, detailQuery, form, uuid]);

	return (
		<Card>
			<Form
				form={form}
				onFinish={async (v) => {
					v.commodity = v.commodity.map((a) => {
						a.totalPrice = roundFix4(a.totalCubic * a.unitPrice);
						return a;
					});

					try {
						const rq = await honoClient.updateTransaction.$post({
							json: v,
						});

						const opRes = await rq.json();
						if (opRes.code === WOOD_CODE.OK) {
							message.success('更新成功', 2, () => {
								navigate('/wood/transaction');
							});

							return;
						}

						message.warning(`更新失败${JSON.stringify(opRes)}`, 2);
					} catch (err) {
						console.log(err);

						if (err?.message) {
							message.error(err?.message);
						}
					}
				}}
			>
				<h3 className="mt-3">送货单编辑</h3>
				{/* <Form.Item shouldUpdate>
						{(c) => {
							return (
								<div>
									{JSON.stringify(c.getFieldsValue(true), undefined, 4)}
								</div>
							);
						}}
					</Form.Item> */}
				<Form.Item name={'uuid'} hidden>
					<Input />
				</Form.Item>
				<Form.Item name={'client'} label="客户">
					<Input />
				</Form.Item>

				<Form.Item label="货品">
					<table>
						<thead>
							<tr>
								<td>品牌</td>
								<td>规格</td>
								<td>件数</td>
								<td>立方数</td>
								<td>单价</td>
								{globalManagement && <td>总成本</td>}
								<td>包号</td>
								<td>total-price</td>
								<td>类型</td>
							</tr>
						</thead>
						<tbody>
							<Form.Item shouldUpdate noStyle>
								{(c) => {
									const commodity = c.getFieldValue('commodity');

									return commodity?.map((cmd, idx) => {
										return (
											<tr key={idx}>
												<td>
													<Form.Item name={['commodity', idx, 'brand']}>
														<Input />
													</Form.Item>
												</td>
												<td>
													<Form.Item
														name={['commodity', idx, 'specifications']}
													>
														<Input />
													</Form.Item>
												</td>
												<td>
													<Form.Item name={['commodity', idx, 'count']}>
														<InputNumber />
													</Form.Item>
												</td>
												<td>
													<Form.Item name={['commodity', idx, 'totalCubic']}>
														<InputNumber />
													</Form.Item>
												</td>
												<td>
													<Form.Item name={['commodity', idx, 'unitPrice']}>
														<InputNumber />
													</Form.Item>
												</td>
												{globalManagement && (
													<td>
														<Form.Item name={['commodity', idx, 'totalCost']}>
															<InputNumber />
														</Form.Item>
													</td>
												)}
												<td>
													<Form.Item
														name={['commodity', idx, 'brSet']}
														getValueProps={(value) => {
															return {
																value: value?.join(' '),
															};
														}}
														normalize={(value) => {
															return value?.split(' ');
														}}
													>
														<Input />
													</Form.Item>
												</td>
												<td>
													<Form.Item shouldUpdate>
														{({ getFieldValue }) => {
															const totalCubic =
																getFieldValue([
																	'commodity',
																	idx,
																	'totalCubic',
																]) ??
																getFieldValue(['commodity', idx, 'cubic']);
															return (
																<div>
																	{roundFix2(
																		totalCubic *
																			getFieldValue([
																				'commodity',
																				idx,
																				'unitPrice',
																			]),
																	)}
																</div>
															);
														}}
													</Form.Item>
												</td>
												<td>
													<Form.Item name={['commodity', idx, 'type']}>
														<Input />
													</Form.Item>
												</td>
											</tr>
										);
									});
								}}
							</Form.Item>
						</tbody>
					</table>
				</Form.Item>

				<div className="flex flex-row gap-2">
					<div className="w-16">总立方数</div>
					<Form.Item shouldUpdate noStyle>
						{({ getFieldValue }) => {
							const commodity = getFieldValue(['commodity']);

							const totalCub = commodity?.reduce((p, c) => {
								return c.totalCubic + p;
							}, 0);

							return <div>{roundFix2(totalCub)}</div>;
						}}
					</Form.Item>
				</div>
				<div className="flex flex-row gap-2">
					<div className="w-16">总价</div>
					<Form.Item shouldUpdate noStyle>
						{({ getFieldValue }) => {
							const commodity = getFieldValue(['commodity']);

							const totalPrice = commodity?.reduce((p, c) => {
								return roundFix2((c?.totalCubic ?? c?.cubic) * c.unitPrice) + p;
							}, 0);

							return <div>{roundFix2(totalPrice)}</div>;
						}}
					</Form.Item>
				</div>
				<div>
					{isNil(detailQuery?.data?.commodity) ? '本单正常' : '本单已经修改'}
				</div>

				<div className="flex flex-row my-3 gap-2">
					<Button htmlType="submit" type="primary">
						更新
					</Button>
					<div>
						{!isNil(detailQuery?.data?.commodity) && (
							<Popconfirm
								title={'确认作废'}
								onConfirm={async () => {
									const res = await honoClient.voidModifiedPartOfDelivery.$post(
										{
											json: {
												uuid: uuid,
											},
										},
									);

									const resOp = await res.json();

									if (resOp.code === WOOD_CODE.OK) {
										await message.success('成功');
										location.reload();
										return;
									}

									message.error('有误');
								}}
							>
								<Button>作废修改内容</Button>
							</Popconfirm>
						)}
					</div>
				</div>
			</Form>
		</Card>
	);
}
export function roundFix4(a: number) {
	return Math.round(a * 10000) / 10000;
}
